.main {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--text-color);
    padding-left: 1rem;
    position: relative;
    overflow-x: hidden;
    max-width: 100%;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}
.mainInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}
.name {
    display: flex;
    align-items: center;
    gap: 6px;
}
.name h2 {
    font-size: 1.4em;
    font-weight: 200;
    text-transform: capitalize;
}
.name p {
    font-weight: 200;
    font-size: 16px;
}
.photoWrapper {
    height: 100%;
    margin-top: 2rem;
    max-width: calc(1400px - 2rem - 286px);
    width: 100%;
    position: relative;
}
.photoWrapper img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: pointer;
}
.actions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 1rem;
}
.action {
    background-color: var(--bg-card);
    transition-duration: .3s;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem .5rem;
    gap: .5rem;
    margin-bottom: 1rem;
}
.action svg {
    font-size: 38px;
    transition-duration: .3s;
    color: var(--text-color);
    opacity: .7;
}
.action p {
    color: var(--text-color);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    transition-duration: .3s;
    opacity: .7;
}
.action:hover svg {
    transform: scale(1.1);
    color: var(--primary-color);
    opacity: 1;
}
.action:hover p {
    color: var(--primary-color);
    opacity: 1;
}
.liked {
    background-color: var(--bg-second);
}
.liked svg {
    color: var(--primary-color);
    opacity: 1;
}
.liked p {
    color: var(--primary-color);
    opacity: 1;
}
.favorite svg {
    color: gold;
    opacity: 1;
}

/* @media screen and (max-width: 1280px) {
    .photoWrapper {
        width: calc(1400px - 2rem - 406px);
    }
} */

@media screen and (max-width: 1170px) {
    .photoWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 1023px) {
    .main {
        padding-right: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .actions button p {
        display: none;
    }
}

@media screen and (max-width: 468px) {
    .header {
        flex-direction: column;
        gap: 1rem;
    }
    .mainInfo {
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .header button {
        width: 100% !important;
    }
    .photoWrapper {
        margin-top: 1rem;
    }
}