.main {
    width: 100%;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 1rem;
}
.main p {
    font-size: 16px;
    font-weight: 500;
}
.buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.main button {
    margin: 0 auto;
}