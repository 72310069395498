.main {
    border: 2px solid var(--primary-color);
    padding: .625rem;
    background-color: var(--bg-card);
    display: flex;
    flex-direction: column;
}
.closeBut {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    color: var(--primary-color);
    font-size: 32px;
    margin-left: auto;
}