.main {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    gap: 2px;
    transition-duration: .3s;
    color: var(--text-color);
    opacity: .7;
    font-weight: 600;
    font-family: "Roboto";
}
.main:hover {
    opacity: 1;
}