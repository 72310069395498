.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container {
    display: flex;
    gap: 10px;
    padding-top: 46px;
    padding-left: 1rem;
    padding-right: 1rem;
}
.content {
    width: 100%;
    padding-top: 4px;
}
.mobileNotifications {
    display: none;
}

@media screen and (max-width: 1023px) {
    .sidebar, .mainNavigation {
        display: none;
    }
    .container {
        padding-top: 108px;
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
    }
    .chat {
        padding-top: 90px;
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        box-sizing: border-box;
    }
    .chat .content {
        padding-top: 0;
    }
    .mobileNotifications {
        width: 100%;
        max-width: 100%;
        display: flex;
        gap: 8px;
        overflow-x: auto;
        padding: 0 .5rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 468px) {
    .container {
        padding-top: 92px;
    }
}