.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.usersWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding-top: 8px;
    width: 100%;
}
.message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    background-color: var(--bg-second);
    padding: 1rem 1rem;
    margin-top: 8px;
}