.footer {
    background-color: black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    margin-top: auto;
}
.footer a {
    color: var(--primary-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
}
.footer p {
    color: var(--primary-color);
    font-size: 14px;
}
.links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.divider {
    width: 1px;
    height: 20px;
    background-color: var(--primary-color);
}