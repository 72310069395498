.main {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: var(--bg-card);
    padding: 1rem;
}
.mainMobile {
    display: none;
}
.userPhoto {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.userPhoto img {
    object-fit: cover;
    object-position: center;
    width: 100%;
}
.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
}
.username {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color);
}
.progressSub {
    color: var(--text-color);
    font-size: 12px;
}
.progress {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: var(--bg);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
}
.progressBar {
    position: absolute;
    height: 100%;
    left: 0;
    background-color: #16be36;
    transition-duration: .3s;
}
.sub {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addPhotoBut {
    font-size: 44px;
    color: #888;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    transition-duration: .3s;
}
.addPhotoBut:hover {
    color: var(--primary-color);
}

@media screen and (max-width: 568px) {
    .main {
        display: none;
    }
    .mainMobile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: var(--bg-card);
        padding: 1rem;
    }
    .mobileContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}