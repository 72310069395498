.usersWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding-top: 8px;
    width: 100%;
}

@media screen and (max-width: 568px) {
    .usersWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}