.main {
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-second);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.notMe {
    background-color: var(--bg-card);
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h4 {
    color: var(--text-color);
    font-size: 20px;
}
.header button {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    border: none;
    color: var(--text-color);
    font-size: 32px;
}
.header button:hover {
    color: var(--primary-color);
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}
.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1rem;
}
.buttons button {
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    padding: .5rem 1rem;
}
.cancel {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.cancel:hover {
    background-color: var(--primary-color-hover);
}
.submit {
    background-color: var(--blue);
    border: 1px solid var(--blue)
}
.submit:hover {
    background-color: var(--blue-active);
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.action {
    background-color: transparent;
    border: 1px solid var(--primary-color);
}
.action:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 1170px) {
    .wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 568px) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 380px) {
    .wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}