.main {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header {
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-card);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h4 {
    transition-duration: .3s;
    color: var(--text-color);
    font-size: 20px;
}
.header span svg {
    transition-duration: .3s;
    transform: rotate(180deg);
    color: var(--text-color);
    font-size: 48px;
}
.header:hover h4 {
    color: var(--primary-color);
}
.headerTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}
.headerTitle svg {
    font-size: 24px;
    color: var(--primary-color);
}
.open span svg {
    transform: rotate(0deg);
}
.static {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--bg-second);
}
.staticInfo {
    display: flex;
    align-items: center;
    color: var(--text-color);
}
.staticInfo p {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    font-size: 26px;
    font-weight: 300;
}
.staticInfo span {
    font-size: 18px;
}
.static button {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    border: none;
    color: var(--text-color);
    font-size: 32px;
}
.static button:hover {
    color: var(--primary-color);
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--bg-second);
    padding: 1rem .5rem;
}
.container {
    max-width: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.field label {
    color: var(--text-color);
    font-size: 18px;
}
.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    margin-top: 1rem;
}
.buttons button {
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    padding: .5rem 1rem;
}
.cancel {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.cancel:hover {
    background-color: var(--primary-color-hover);
}
.submit {
    background-color: var(--blue);
    border: 1px solid var(--blue)
}
.submit:hover {
    background-color: var(--blue-active);
}