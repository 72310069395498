.main {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--bg-second);
}
.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 2px solid var(--bg-second);
}
.buttons button, .buttons label {
    width: 100% !important;
    height: 51px !important;
}
.buttons label {
    background-color: var(--bg-second);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    transition-duration: .3s;
    border-bottom: 2px solid var(--bg-second);
}
.buttons label:hover {
    background-color: var(--bg);
}
.inputs {
    background-color: var(--bg);
    width: 100%;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.previewContainer {
    position: relative;
    width: 90px;
    height: 90px;
}
.previewContainer button {
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    opacity: .7;
    transition-duration: .3s;
    border: none;
    outline: none;
    color: var(--text-color);
    font-size: 20px;
    top: 0px;
    right: -10px;
    width: fit-content;
}
.previewContainer button:hover {
    opacity: 1;
}
.inputs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-left: .3rem;
}

.window .buttons button, .window .buttons label {
    height: 31px !important;
}
.window .inputs {
    height: 62px;
}
.window .previewContainer {
    height: 62px;
}
.window .previewContainer {
    width: 50px;
    height: 50px;
}