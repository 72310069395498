.message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    background-color: var(--bg-second);
    padding: 1rem 1rem;
    margin-top: 8px;
}