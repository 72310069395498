.main {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-second);
    color: var(--text-color);
    padding: .5rem;
}
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 44px - 54px - 8px - 32px - 62px);
    overflow-y: auto;
}