.main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding: 8px 0;
}

@media screen and (max-width: 1023px) {
    .main {
        padding: 0 8px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 568px) {
    .main {
        grid-template-columns: repeat(2, 1fr);
    }
}