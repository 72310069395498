.main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.main svg {
    opacity: .5;
    position: absolute;
    color: var(--text-color);
}
.red {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    opacity: .5;
}
.small {
    width: 120px;
    height: 120px;
}
.big {
    width: 214px;
    aspect-ratio: 1 / 1;
}
.small svg {
    font-size: 44px;
}
.big svg {
    font-size: 50px;
}
.main button {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: var(--bg-card);
    width: 25px;
    height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.main button:hover svg {
    opacity: .7 !important;
}
.main button svg {
    color: var(--text-color);
    font-size: 24px;
    opacity: 1 !important;
    transition-duration: .3s;
}