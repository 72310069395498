.main {
    width: 600px;
    height: 600px;
    position: relative;
}
.slidePhoto {
    width: 600px;
    height: 600px;
    object-fit: cover;
}
.closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    opacity: .7;
    transition-duration: .3s;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    color: var(--text-color);
    z-index: 2;
}
.closeButton:hover {
    opacity: 1;
}

@media screen and (max-width: 630px) {
    .main {
        width: 95%;
        height: 45vh;
    }
    .slidePhoto {
        width: 100%;
        height: 100%;
    }
    .slidePhoto {
        object-fit: cover;
        object-position: center;
    }
}