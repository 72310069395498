.main {
    opacity: .9;
    overflow: hidden;
    box-shadow: 0 0 .5rem var(--primary-color);
    max-width: 28rem;
    width: 90%;
    background-color: black;
    padding: 1.25rem;
}

@media screen and (max-width: 1024px) {
    .main {
        max-width: 100%;
        width: 100%;
    }
}