.main {
    border-radius: 50%;
}
.small {
    width: .5rem;
    height: .5rem;
}
.big {
    width: 12px;
    height: 12px;
}