.main {
    width: 100%;
    border-bottom: 1px solid var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: black;
    z-index: 10;
}
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 1rem;
}
.mobileContainer {
    display: none !important;
}
.logo {
    color: white;
    font-weight: 900;
    text-decoration: none;
    font-size: 20px;
}
.menu {
    position: relative;
}
.menu:hover .burger {
    background-color: var(--bg)
}
.burger {
    transition-duration: .3s;
    color: var(--primary-color);
    font-size: 28px;
    padding: 0 .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.collapse {
    position: absolute;
    background-color: var(--bg) !important;
    color: var(--text-color) !important;
    padding: 1rem;
    right: 0 !important;
    top: 30px !important;
    display: flex !important;
    flex-direction: column !important;
}
.link {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    text-decoration: none;
    transition-duration: .3s;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
}
.link:hover {
    background-color: var(--primary-color);
}
.big {
    padding: .75rem 1rem;
    border-bottom: 1px solid black
}
.small {
    display: flex;
    flex-direction: column;
}
.smallLink {
    padding: .5rem 1rem;
    font-size: .75rem;
}
.right {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.buySub {
    background-color: var(--primary-color);
    cursor: pointer;
    outline: none;
    border: 1px solid var(--primary-color);
    transition-duration: .3s;
    color: var(--text-color);
    font-weight: 600;
    padding: .3rem;
}
.buySub:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 1023px) {
    .container {
        padding: .5rem;
    }
    .mobileContainer {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 .5rem;
        box-sizing: border-box;
    }
    .searchButt {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        color: var(--grey);
        font-weight: 600;
    }
    .searchButt svg {
        font-size: 28px;
    }
}

@media screen and (max-width: 568px) {
    .mobileContainer {
        justify-content: flex-start;
        gap: 8px;
    }
    .searchButt {
        width: 100%;
    }
    .searchButt span {
        display: none;
    }
    .searchButt svg {
        font-size: 24px;
    }
}