.main {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header {
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-card);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h4 {
    transition-duration: .3s;
    color: var(--text-color);
    font-size: 20px;
}
.header span svg {
    transition-duration: .3s;
    transform: rotate(180deg);
    color: var(--text-color);
    font-size: 48px;
}
.header:hover h4 {
    color: var(--primary-color);
}
.headerTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}
.headerTitle svg {
    font-size: 24px;
    color: var(--primary-color);
}
.open span svg {
    transform: rotate(0deg);
}