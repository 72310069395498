.main {
    padding: .3125rem;
    background-color: var(--bg);
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.hotCounters {
    width: 100%;
    display: flex;
    gap: 8px;
}
.hotBlock {
    width: 100%;
    height: 100%;
    background-color: var(--bg-card);
    transition-duration: .3s;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    text-decoration: none;
}
.hotBlock:hover {
    background-color: var(--bg-third);
    box-shadow: 0 0 .8rem rgba(0,0,0,.5);
}
.hotBlock:hover svg {
    transform: scale(1.05);
}
.hotInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #888
}
.hotInfo svg {
    font-size: 64px;
    transition-duration: .3s;
}
.counter {
    font-size: 2rem;
    margin-top: 1rem;
}
.counterLabel {
    margin-top: 4px;
}
.hotBlock button {
    width: 100% !important;
}
.userInfo {
    width: 100%;
}

@media screen and (max-width: 1023px) {
    .main {
        padding: 0;
        gap: .5rem;
        background-color: transparent;
    }
}

@media screen and (max-width: 568px) {
    .hotBlock {
        gap: 1rem;
    }
    .hotInfo svg {
        font-size: 44px;
        transition-duration: .3s;
    }
}

@media screen and (max-width: 435px) {
    .counterLabel {
        display: none;
    }
}