.main {
    width: 100%;
    display: flex;
    margin-top: .5rem;
    background-color: var(--bg-card);
}
.list {
    width: 30%;
}
.chat {
    width: 70%;
}
.full {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .list {
        width: 100%;
    }
    .hide {
        display: none;
    }
}