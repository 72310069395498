.main {
    width: fit-content;
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    z-index: 10;
}
.chatsButt {
    display: none;
}

@media screen and (max-width: 458px) {
    .chatsButt {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 26px !important;
        color: var(--text-color) !important;
        background-color: var(--primary-color) !important;
        padding: 1rem !important;
    }
}