.main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    border: 1px solid var(--bg-second);
}
.user {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: left;
}
.photo {
    position: relative;
    cursor: pointer;
}
.photo img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    object-fit: cover;
}
.online {
    position: absolute;
    right: 5px;
    bottom: 5px;
}
.userInfo {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: var(--text-color);
}
.userInfo p::first-letter {
    text-transform: capitalize;
}
.name {
    cursor: pointer;
    width: fit-content;
}
.actions {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.likeFavorite {
    display: flex;
    align-items: center;
    gap: .7rem;
}
.actions button {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    border: none;
    font-size: 26px;
    opacity: .5;
    color: var(--text-color);
}
.actions button:hover {
    color: var(--primary-color);
    opacity: 1;
}
.active {
    color: var(--primary-color) !important;
    opacity: 1 !important;
}
.backButt {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    font-size: 28px;
}

@media screen and (max-width: 468px) {
    .main {
        padding: .5rem;
        gap: .5rem;
    }
    .user {
        gap: .5rem;
    }
    .actions {
        gap: .5rem;
        flex-wrap: wrap;
        align-items: flex-end;
        flex-direction: column;
    }
    .actions button {
        font-size: 18px;
    }
    .backButt {
        font-size: 22px;
        padding: 0;
    }
    .likeFavorite {
        gap: .5rem;
    }
}