.main {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.main img {
    width: 100%;
    position: absolute;
    transition-duration: .3s;
    height: 100%;
    object-fit: cover;
}
.main:hover img {
    transform: scale(1.05);
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(16,16,16,.8);
    padding: .5rem;
    display: flex;
    flex-direction: column;
}
.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: var(--text-color);
}
.userInfo {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--text-color);
}
.info + .info {
    font-weight: 500;
    font-size: 14px;
}
.footFoot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.actions {
    display: flex;
    align-items: center;
    gap: 8px;
}
.actions a, .actions button {
    background-color: transparent;
    color: var(--text-color);
    transition-duration: .3s;
    opacity: .7;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
}
.actions a:hover, .actions button:hover {
    opacity: 1;
}
.liked svg {
    color: var(--primary-color);
}

@media screen and (max-width: 1170px) {
    .main {
        height: 200px;
    }
}


@media screen and (max-width: 568px) {
    .main {
        height: 230px
    }
}

@media screen and (max-width: 458px) {
    .main {
        height: 210px
    }
}

@media screen and (max-width: 400px) {
    .main {
        height: 185px
    }
}

@media screen and (max-width: 350px) {
    .main {
        height: 160px
    }
}