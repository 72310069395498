.main {
    background-color: var(--bg-card);
    width: 50%;
    max-height: 85vh;
    height: fit-content;
    overflow-y: auto;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    padding: .625rem;
    color: var(--text-color);
}
.header button {
    background-color: transparent;
    color: var(--text-color);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    background-color: var(--bg-card);
    padding: 1.25rem;
}

@media screen and (max-width: 968px) {
    .main {
        width: 95%;
    }
}