.main {
    width: 100%;
    min-height: 100vh;
    background-image: url("../../../../Assets/regform-mood.jpg");
    background-size: cover;
    background-position: left;
    background-position-x: 160%;
    background-position-y: 20%;
    background-attachment: fixed;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.header {
    width: 100%;
    background-color: black;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--primary-color);
}
.header h2 {
    color: white;
}
.buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    padding-top: 2rem;
}

@media screen and (max-width: 1024px) {
    .content {
        height: fit-content;
    }
}