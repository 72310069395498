.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
    text-align: left;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.radio {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}
.rules {
    color: white;
    font-size: 14px;
    text-align: left;
    width: 100%;
    display: inline;
}
.rules p {
    width: fit-content;
}
.submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}