.main {
    width: 254px;
    display: flex;
    flex-direction: column;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
}
.userInfo {
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 100%;
    transition-duration: .3s;
    cursor: pointer;
    text-decoration: none;
}
.userInfo:hover {
    background-color: var(--primary-color-hover);
}
.userInfo img {
    width: 32px;
    height: 32px;
    object-fit: cover;
}
.userInfo p {
    font-size: 14px;
    color: var(--text-color);
}
.userInfo p::first-letter {
    text-transform: uppercase;
}
.actions {
    display: flex;
    align-items: center;
}
.action {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    height: 32px;
    outline: none;
    border: none;
    color: var(--text-color);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action:hover {
    background-color: var(--primary-color-hover);
}
.collapse {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.chat {
    max-height: 250px;
    overflow-y: auto;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
    background-color: var(--bg-second);
}
.main textarea {
    font-size: 13px !important;
}

@media screen and (max-width: 468px) {
    .main {
        display: none;
    }
}