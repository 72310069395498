body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

:root {
  --primary-color: #a10000;
  --primary-color-hover: #6e0000;
  --text-color: white;
  --link-color: white;
  --link-color-hover: #a10000;
  --font-family: 'Roboto';
  --bg-card: #101010;
  --bg: #363636;
  --bg-second: #1d1d1d;
  --bg-third: #232323;
  --bg-message: #505050;
  --blue: #00b9ee;
  --blue-active: #009dca;
  --grey: #909090;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-end: 0;
  margin-block-start: 0;
}

.swiper {
  max-width: calc(100vw - 295px - 4rem);
  margin: 0 !important;
}
.swiper-button-prev, .swiper-button-next {
  color: var(--text-color) !important;
}
/* .swiper-slide {
  max-height: 300px !important;
  width: 300px !important;
} */

@media screen and (max-width: 1280px) {
  .swiper {
    max-width: calc(100vw - 295px - 1rem)
  }
}

@media screen and (max-width: 1023px) {
  .swiper {
    max-width: calc(100%);
  }
}