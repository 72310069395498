.main {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
}
.photo {
    position: relative;
}
.photo img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    object-fit: cover;
}
.photo svg {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 55px;
    color: var(--primary-color);
}
.name {
    font-size: 34px;
    color: var(--primary-color);
    font-weight: 300;
}
.name::first-letter {
    text-transform: capitalize;
}
.text {
    font-size: 20px;
    color: var(--text-color);
    font-weight: 400;
    max-width: 80%;
}
.cta {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    font-size: 18px;
    color: var(--text-color);
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    padding: .5rem 1rem;
    font-weight: 600;
    text-transform: uppercase;
}
.cta:hover {
    background-color: var(--blue-active);
}