.main {
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-second);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.notMe {
    background-color: var(--bg-card);
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h4 {
    color: var(--text-color);
    font-size: 20px;
}
.header button {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    border: none;
    color: var(--text-color);
    font-size: 32px;
}
.header button:hover {
    color: var(--primary-color);
}
.static {
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}
.fact {
    max-width: 22%;
    width: 100%;
    color: var(--text-color);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.fact label {
    font-size: 12px;
    text-align: left;
}
.fact p {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.fields {
    width: 100%;
    display: flex;
    gap: 1rem;
}
.field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.field label {
    color: var(--text-color);
    font-size: 14px;
}
.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    margin-top: 1rem;
}
.buttons button {
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    padding: .5rem 1rem;
}
.cancel {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.cancel:hover {
    background-color: var(--primary-color-hover);
}
.submit {
    background-color: var(--blue);
    border: 1px solid var(--blue)
}
.submit:hover {
    background-color: var(--blue-active);
}

@media screen and (max-width: 468px) {
    .fact {
        max-width: 100%;
    }
}