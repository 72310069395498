.main {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--bg-second);
    position: relative;
}
.main:hover .content {
    border: 2px solid var(--primary-color);
    border-left: none;
}
.profilePhoto {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    object-fit: cover;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    gap: 4px;
    color: var(--text-color);
    position: relative;
    width: 100%;
    border: 2px solid var(--bg-second);
    border-left: none;
    transition-duration: .3s;
    height: 60px;
    padding-left: .5rem;
}
.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
}
.content h6 {
    font-weight: 200;
    font-size: 16px;
    text-transform: capitalize;
}
.content p {
    font-size: 12px;
    font-family: 200;
}
.remove {
    position: absolute;
    right: 0px;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    opacity: .7;
    transition-duration: .3s;
    color: var(--text-color);
}
.remove:hover {
    opacity: 1;
}
.action {
    transition-duration: .3s;
    width: 0;
    height: 60px;
    position: absolute;
    right: -2px;
    background-color: var(--primary-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    cursor: pointer;
    border: none;
    transition-delay: .4s;
}
.main:hover .action {
    width: calc(100% + 2px);
    opacity: 1;
}
.main:hover .remove:hover + .action {
    width: 0 !important;
    opacity: 0 !important;
}

@media screen and (max-width: 1023px) {
    .main {
        min-width: 300px;
    }
    .action {
        display: none;
    }
    .remove {
        position: relative;
        margin-left: auto;
        font-size: 22px;
    }
    .content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}