.main {
    width: 100%;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition-duration: .3s;
    border-bottom: 1px solid var(--bg-second);
    border-left: 1px solid var(--bg-second);
    border-right: 1px solid var(--bg-second);
    position: relative;
}
.main:hover {
    background-color: var(--primary-color-hover);
}
.active {
    background-color: var(--primary-color);
}
.active:hover {
    background-color: var(--primary-color);
}
.info {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.image {
    position: relative;
}
.image img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    object-fit: cover;
}
.name {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: var(--text-color);
    text-align: left;
}
.name p::first-letter {
    text-transform: capitalize;
}
.online {
    position: absolute;
    bottom: 5px;
    right: 5px;
}
.counter {
    width: 30px;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    background-color: var(--primary-color);
    border-radius: 50%;
    transition-duration: .3s;
}
.removeBut {
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    color: var(--text-color);
    transition-duration: .3s;
    opacity: .7;
    border: none;
    outline: none;
    font-size: 12px;
}
.removeBut:hover {
    opacity: 1;
}
.active .counter {
    background-color: var(--bg-card);
}