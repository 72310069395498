.main {
    margin-top: .5rem;
    background-color: var(--bg-card);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}
.main h4 {
    color: var(--primary-color);
    font-size: 32px;
}
.langs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 1rem;
    background-color: black;
    box-shadow: 0 0 10px -1px var(--primary-color)
}
.langs button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    color: var(--primary-color);
    text-align: left;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
}
.langs button:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1023px) {
    .langs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 468px) {
    .langs {
        grid-template-columns: repeat(1, 1fr);
    }
    .main h4 {
        font-size: 26px;
    }
}