.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.buttons {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
}
.buttons button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-third);
    transition-duration: .3s;
    color: #909090;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0 1.1rem;
    height: 3.125rem;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
}
.buttons button svg {
    font-size: 24px;
}
.buttons button:hover {
    color: white;
}
.mainMobile {
    display: none;
}
.mainMobile button {
    position: relative;
}
.mainMobile svg {
    transition-duration: .3s;
}
.counter {
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: .5rem;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active svg {
    color: var(--primary-color);
}

@media screen and (max-width: 1023px) {
    .main {
        display: none;
    }
    .mainMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .mainMobile button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        transition-duration: .3s;
        color: #909090;
        outline: none;
        cursor: pointer;
        border: none;
        padding: 0 1.1rem;
        height: 3.125rem;
        text-transform: uppercase;
        font-size: 14px;
    }
    .mainMobile button svg {
        font-size: 24px;
    }
}