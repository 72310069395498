.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--bg-third);
    padding: .5rem;
}
.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: var(--text-color);
    transition-duration: .3s;
}
.title p {
    transition-duration: .3s;
    font-size: 16px;
    font-weight: 600;
}
.title:hover {
    color: var(--primary-color);
}
.title svg {
    font-size: 28px;
    transform: rotate(180deg);
    transition-duration: .3s;
}
.open svg {
    transform: rotate(0);
}
.sections {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.sectionTitle {
    width: 100%;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-image: linear-gradient(to right,#800,transparent) 1;
    padding-bottom: .25rem;
    text-align: left;
    color: var(--text-color);
    display: block;
    font-size: 14px;
}
.buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}
.buttons button {
    width: 100% !important;
    font-size: 14px !important;
}
.activeButton {
    background-color: var(--bg-message) !important;
    border: 1px solid transparent !important;
}
.activeButton:hover {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}
.ageButton {
    padding: .5rem !important;
}
.preferencesButtons {
    flex-wrap: wrap;
    gap: 4px;
}
.preferencesButtons button {
    width: fit-content !important;
    padding: .4rem !important;
    font-size: .9em !important;
}
.preferencesControls {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
} 
.controlButton {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: var(--text-color);
    font-size: 14px;
    padding: .4rem;
    transition-duration: .3s;
}
.controlButton:hover {
    background-color: var(--primary-color);
}

.fireButton {
    /* border-image: linear-gradient(to right, rgba(255, 0, 0, 0.7), rgba(255, 50, 0, 0.7), rgba(255, 100, 0, 0.7), rgba(255, 0, 0, 0.7)) 1 !important; */
  animation: fire 2s linear infinite;
}



@keyframes fire {
    0% {
      box-shadow: 0 0 2px rgba(161, 0, 0, 0.5),   /* Base red shadow */
                  0 0 4px rgba(161, 50, 0, 0.5), 
                  0 0 6px rgba(255, 100, 0, 0.5), 
                  0 0 8px rgba(161, 0, 0, 0.5);
    }
    50% {
      box-shadow: 0 0 4px rgba(161, 0, 0, 0.5), 
                  0 0 8px rgba(161, 50, 0, 0.5), 
                  0 0 12px rgba(255, 100, 0, 0.5), 
                  0 0 16px rgba(161, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 2px rgba(161, 0, 0, 0.5),   /* Base red shadow */
                  0 0 4px rgba(161, 50, 0, 0.5), 
                  0 0 6px rgba(255, 100, 0, 0.5), 
                  0 0 8px rgba(161, 0, 0, 0.5);
    }
  }

@media screen and (max-width: 1024px) {
    .activeButton:hover {
        background-color: var(--bg-message) !important;
        border: 1px solid transparent !important;
    }
}