.main {
    padding: .5rem 1rem;
    transition-duration: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-third);
    color: var(--text-color);
}
.main:hover {
    background-color: var(--primary-color-hover);
}
.main p {
    font-size: 14px;
}
.selected {
    background-color: var(--primary-color);
}
.notMe {
    cursor: default;
}
.notMe:hover {
    background-color: var(--bg-third) !important;
}
.circle {
    background-color: var(--bg-card);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
}
.circle svg {
    transition-duration: .3s;
    opacity: 0;
}
.selected svg {
    opacity: 1;
}

@media screen and (max-width: 1280px) {
    .main p {
        text-align: left;
    }
}