.main {
    width: 276px;
    display: flex;
    flex-direction: column;
}
.userInfo {
    background-color: var(--bg-card);
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.userInfo a {
    color: white;
    text-decoration: none;
}
.userPhoto {
    width: 80px;
    height: 80px;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
}
.userPhoto img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.userInfoData {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: left;
}
.notifications {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    margin-top: .5rem;
}
.buySubscription {
    width: 100%;
    padding: .5rem .5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    transition-duration: .3s;
    cursor: pointer;
    outline: none;
    margin-bottom: .5rem;
}
.buySubscription:hover {
    background-color: var(--primary-color-hover);
}
.buySubscription svg {
    font-size: 32px;
    color: var(--text-color);
}