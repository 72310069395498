.h1 {

}
.h2 {

}
.h3 {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
}
.h4 {

}
.h5 {

}