.main {
    max-width: 70%;
    width: 100%;
    display: flex;
    gap: 1rem;
    height: fit-content;
    margin-top: auto;
}
.me {
    flex-direction: row-reverse;
    margin-left: auto;
}
.small {
    max-width: 90%;
}
.userPhoto {
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    border-radius: 50%;
    border: 1px solid var(--text-color);
    object-fit: cover;
    margin-top: auto;
}
.small .userPhoto {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
}
.message {
    background-color: var(--bg-message);
    padding: .5rem;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
    height: fit-content;
    text-align: left;
}
.me .message {
    background-color: var(--bg-third);
}
.small .message {
    padding: .3rem;
}
.dateTime {
    font-size: 12px;
}
.small .dateTime {
    font-size: 10px;
}
.text {
    white-space: pre-wrap;
    font-size: 15px;
}
.small .text {
    font-size: 12px;
}
.photo {
    width: 200px;
    height: auto;
    cursor: pointer;
}
.small .photo {
    width: 100px;
}

@media screen and (max-width: 568px) {
    .main {
        max-width: 90%;
    }
}