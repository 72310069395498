.main {
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-second);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h4 {
    color: var(--text-color);
    font-size: 20px;
}
.header button {
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    border: none;
    color: var(--text-color);
    font-size: 32px;
}
.header button:hover {
    color: var(--primary-color);
}
.headerText {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    color: var(--text-color);
}
.headerText span {
    font-size: 12px;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--bg-second);
}
.field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 50%;
}
.field label {
    color: var(--text-color);
    font-size: 18px;
}
.buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1rem;
    margin-left: auto;
}
.buttons button {
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition-duration: .3s;
    outline: none;
    padding: .5rem 1rem;
}
.cancel {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.cancel:hover {
    background-color: var(--primary-color-hover);
}
.submit {
    background-color: var(--blue);
    border: 1px solid var(--blue)
}
.submit:hover {
    background-color: var(--blue-active);
}

@media screen and (max-width: 468px) {
    .field {
        width: 100%;
    }
}