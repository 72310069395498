.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.main label {
    font-family: var(--font-family);
    font-size: 14px !important;
    color: var(--text-color);
    font-weight: 500;
}
.main svg {
    color: white !important;
}