.main {
    max-width: 40%;
    width: 100%;
    height: 100%;
    max-height: 60vh;
    margin: auto;
}
.image {
    position: relative;
    width: 100%;
    height: 100%;
}
.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.image button {
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--text-color);
    opacity: .7;
    font-size: 32px;
    cursor: pointer;
    border: none;
    transition-duration: .3s;
}
.image button:hover {
    opacity: 1;
}
.input {
    width: 100%;
}
.chat {
    padding: 2px;
    background-color: var(--bg-second);
    display: flex;
    align-items: center;
}
.chat button {
    height: 80px !important;
}

@media screen and (max-width: 1023px) {
    .main {
        max-width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .main {
        max-width: 95%;
    }
}