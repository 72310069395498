.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 44px - 54px - 8px - 85px - 102px - 62px - env(safe-area-inset-bottom));
    height: calc(100vh - 44px - 54px - 8px - 85px - 102px - 62px - env(safe-area-inset-bottom));
    overflow-y: auto;
    border: 1px solid var(--bg-second);
    border-top: none;
    padding: 1rem
}
.isTyping {
    color: var(--text-color);
    text-align: left;
    margin-top: 1rem;
    opacity: .4;
}

@media screen and (max-width: 1023px) {
    .main {
        max-height: calc(100vh - 95px - 86px - 104px - env(safe-area-inset-bottom));
        height: calc(100vh - 95px - 86px - 104px - env(safe-area-inset-bottom))
    }
}