.main {
    color: var(--text-color);
    text-align: left;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.main h3 {
    font-size: 1.9em;
    font-weight: 200;
}